import React, { useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import BlogHomeStyles from "./BlogHome.styles";
import BlogPostThumbnail from "./BlogPostThumbnail/BlogPostThumbnail";
import Categories from "./Categories/Categories";
import Pagination from "./Pagination/Pagination";

const {
    Container,
    ContentContainer,
    TitleContainer,
    TitleBoxContainer,
    TitleBox,
    TitleText,
    SubTitleText,
    BlogPostsGrid,
} = BlogHomeStyles;

const BlogHome = () => {
    const blogPostsData = useStaticQuery(graphql`
        query BlogPosts {
            allContentfulBlogPost {
                nodes {
                    lead
                    title
                    categories {
                        singleCategory
                    }
                    id
                    createdAt
                    slug
                    thumbnail {
                        gatsbyImageData(
                            layout: FULL_WIDTH
                            cropFocus: CENTER
                            aspectRatio: 1.333
                        )
                    }
                    content {
                        raw
                    }
                }
            }
            allContentfulCategories {
                nodes {
                    singleCategory
                }
            }
        }
    `);

    const [currentCategory, setCurrentCategory] = useState("All");
    const categorize = (category) => {
        setCurrentCategory(category);
        setCurrentPage(1);
    };
    const [postsPerPage] = useState(6);
    const [currentPage, setCurrentPage] = useState(1);
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const allBlogPosts = blogPostsData.allContentfulBlogPost.nodes;
    const categorizedPosts =
        currentCategory !== "All"
            ? allBlogPosts.filter((node) =>
                  node.categories.find(
                      (x) => x.singleCategory === currentCategory
                  )
              )
            : allBlogPosts;
    const currentPosts = categorizedPosts.slice(
        indexOfFirstPost,
        indexOfLastPost
    );

    return (
        <Container>
            <ContentContainer>
                <TitleContainer id="header">
                    <TitleBoxContainer>
                        <TitleBox>
                            <TitleText>Blog</TitleText>
                        </TitleBox>
                    </TitleBoxContainer>
                </TitleContainer>
                <SubTitleText>
                    We are blogging about tech news, startups, leadership in IT
                    organizations and other IT related topics.
                </SubTitleText>
                <Categories
                    categories={[
                        "All",
                        ...blogPostsData.allContentfulCategories.nodes.flatMap(
                            (elem) => elem.singleCategory
                        ),
                    ]}
                    currentCategory={currentCategory}
                    categorize={categorize}
                />
                <BlogPostsGrid>
                    {currentPosts.map((post) => {
                        return <BlogPostThumbnail post={post} key={post.id} />;
                    })}
                </BlogPostsGrid>
                <Pagination
                    allPosts={categorizedPosts.length}
                    currentPage={currentPage}
                    paginate={paginate}
                    postsPerPage={postsPerPage}
                />
            </ContentContainer>
        </Container>
    );
};

export default BlogHome;
