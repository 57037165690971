import * as React from "react";
import Layout from "../components/Layout/Layout";
import BlogBG from "../components/Blog/Background/BlogBG";
import BlogHome from "../components/Blog/Blog/BlogHome";
import { Helmet } from "react-helmet";

const Blog = () => {
    return (
        <Layout>
            <Helmet>
                <meta charSet="utf-8" />
                <title>OakForge - Blog</title>
                <meta
                    name="description"
                    content="Oakforge - your best starting place in IT world"
                />
            </Helmet>
            <BlogBG />
            <BlogHome />
        </Layout>
    );
};

export default Blog;
