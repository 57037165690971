import styled from "styled-components";

const PaginationStyles = {
    Container: styled.div`
        width: 100%;
        margin-top: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 3px;
    `,

    NumberBox: styled.a`
        text-decoration: none;
        color: #454545;
        border: 2px solid gray;
        border-color: ${(props) => (props.isActive ? "#6c6c6c" : "gray")};
        border-width: ${(props) => (props.isActive ? "3px" : "2px")};
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    `,
};

export default PaginationStyles;
