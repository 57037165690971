import React from "react";
import PaginationStyles from "./Pagination.styles";

const { Container, NumberBox } = PaginationStyles;

const Pagination = ({ allPosts, currentPage, paginate, postsPerPage }) => {
    const pageNumbers = [];
    const pages = Math.ceil(allPosts / postsPerPage);

    if (pages > 6) {
        if (currentPage < 4) {
            for (let i = 0; i < 4; i++) {
                pageNumbers.push(i + 1);
            }
            pageNumbers.push("....");
            pageNumbers.push(pages);
        } else if (currentPage > pages - 4) {
            pageNumbers.push(1);
            pageNumbers.push("...");
            for (let i = pages - 4; i < pages; i++) {
                pageNumbers.push(i + 1);
            }
        } else {
            pageNumbers.push(1);
            pageNumbers.push("...");
            pageNumbers.push(currentPage);
            pageNumbers.push(currentPage + 1);
            pageNumbers.push("....");
            pageNumbers.push(pages);
        }
    } else {
        for (let i = 0; i < pages; i++) {
            pageNumbers.push(i + 1);
        }
    }

    const internatPaginate = (number) => {
        if (number === "...") {
            paginate(Math.floor(currentPage / 2));
        } else if (number === "....") {
            paginate(Math.ceil((pages + currentPage) / 2));
        } else {
            paginate(number);
        }
    };

    return (
        <Container>
            {pageNumbers.map((number, idx) => (
                <NumberBox
                    key={idx}
                    onClick={() => internatPaginate(number)}
                    isActive={currentPage === number}
                    href="#header"
                >
                    {number}
                </NumberBox>
            ))}
        </Container>
    );
};

export default Pagination;
